//#region Imports

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserProxy } from '../../models/proxys/user.proxy';
import { RolesEnum } from '../../models/enums/roles.enum';
import { NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class UserService {

  //#region Constructor

  constructor(
    protected readonly toast: NbToastrService,
    private readonly router: Router,
  ) { }

  //#endregion

  //#region Private Properties

  private readonly currentUser$: BehaviorSubject<UserProxy | null> = new BehaviorSubject<UserProxy | null>(this.getCurrentUser());
  
  //#endregion

  //#region Public Methods

  public getCurrentUser$(): Observable<UserProxy | null> {
    return this.currentUser$.asObservable();
  }

  public getCurrentUser(): UserProxy | null {
    try {
      return JSON.parse(localStorage.getItem(environment.keys.user) || '');
    } catch (e) {
      return null;
    }
  }

  public refreshCurrentUser(): void {
    this.currentUser$.next(this.getCurrentUser());
  }

  public async toggleOperatorView(willActive: boolean): Promise<void> {
    const user: UserProxy | null = this.getCurrentUser();
    
    if (!user)
      return void this.toast.danger('An error ocurred, try again later', 'Ops...');

    if (willActive) {
      user.roles = [RolesEnum.OPERATOR];
      localStorage.setItem(environment.keys.isOperator, 'true');
    } else {
      user.roles = [RolesEnum.MANAGER];
      localStorage.setItem(environment.keys.isOperator, '');
      await this.router.navigate(['pages/dashboard']);
    }

    localStorage.setItem(environment.keys.user, JSON.stringify(user));
    this.refreshCurrentUser();

    window.location.reload();
  }

  //#endregion

}
